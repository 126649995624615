import * as React from "react"
import DefaultLayout from "../components/default-layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <DefaultLayout>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </DefaultLayout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
